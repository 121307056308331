import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Home.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/scoring',
      name: 'scoring',
      component: () => import('@/views/scoring/Scoring.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/Users.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/investors',
      name: 'investors',
      component: () => import('@/views/investors/Investors.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: "/settings/terms",
      component: () => import("@/views/files/Terms.vue"),
      meta: {
        layout: "full",
        middleware: "auth",
      },

    },
    {
      path: "/settings/privacy",
      component: () => import("@/views/files/Privacy.vue"),
      meta: {
        layout: "full",
        middleware: "auth",
      },

    },
    {
      path: '/investor/:id',
      name: 'investors',
      component: () => import('@/components/Buyers/Tabs.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/company/Company.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/todo',
      name: 'todo',
      component: () => import('@/views/company/TodoCompany.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/currency',
      name: 'currency',
      component: () => import('@/views/currency/CurrencyList.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/transaction-currency',
      name: 'transaction_currency',
      component: () => import('@/views/currency/CurrencyTransaction.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/send/email',
      name: 'send_email',
      component: () => import('@/views/Email/SendEmail.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/Partners/Partners.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/partner/:id',
      name: 'partner_details',
      component: () => import('@/views/Partners/PartnerDetails.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/transactions/report',
      name: 'transaction_report',
      component: () => import('@/views/Reports/Transaction.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/email-testing',
      name: 'email_testing',
      component: () => import('@/views/EmailTesting/EmailTesting.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/debtor-ips',
      name: 'debtor_ips',
      component: () => import('@/views/DebtorIPs/DebtorIps.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/failed-emails',
      name: 'failed_emails',
      component: () => import('@/views/FailedEmails/FailedEmails.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/invoices/Invoices.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/invoice/:id',
      name: 'invoices',
      component: () => import('@/views/invoices/Invoice.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/Settings.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/scoring/settings',
      name: 'scoring_settings',
      component: () => import('@/views/settings/ScoringSettings.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/zoho-leads',
      name: 'zoho_leads',
      component: () => import('@/views/zoho/ZohoLeads.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('@/views/documents/Documents.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/company/:id',
      name: 'companies',
      component: () => import('@/views/company/CompanyData.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/companies/add-new',
      name: 'add new company',
      component: () => import('@/views/company/AddNewCompany.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/languages',
      name: 'languages',
      component: () => import('@/views/dashboard/Languages.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: "/read-files",
      name: "Documents",
      component: () => import("@/views/PdfViewer.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: "/files/:file",
      name: "Files",
      component: () => import("@/views/files/Files.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: '/verify',
      redirect: '/',
    },
    {
      path: '/user/:id',
      name: 'users-update',
      component: () => import('@/views/users/Users.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/ip-logins',
      name: 'ip-logins',
      component: () => import('@/views/ip-logins/IpLogins.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/to-do',
      name: 'to-do',
      component: () => import('@/views/to-do/ToDo.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.middleware === 'guest') {
    // eslint-disable-next-line no-undef
    if (store.state.auth.authenticated && store.state.auth.token != null && store.state.auth.user && store.state.auth.user.role && store.state.auth.user.role == 'admin')  {
      next({ name: 'dashboard' })
    }
    next()
    // eslint-disable-next-line no-undef
  } else if (store.state.auth.authenticated && store.state.auth.user && store.state.auth.user.role && store.state.auth.user.role == 'admin') {
    next()
  } else {
    next({ name: 'login' })
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
