export default {
  namespaced: true,
  state: {
    user: 0,
    token: null,
    status: 0,
    authenticated: false,
    resetPasswordEmail: null,
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_USER(state, value) {
      state.user = value
    },
    UPDATE_TOKEN(state, val) {
      state.token = val
    },
    UPDATE_RESET_EMAIL(state, val) {
      state.resetPasswordEmail = val
    },
  },
  actions: {},
}
